import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../layouts'
import Grid from '../components/elements/grid'
import { SizedText } from "../components/elements/typography"

const buttonStyles = {
  border: 'none',
  padding: '0',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  height: '3rem',
  width: '3rem',
  backgroundColor: "#16405b",
  margin: '0 1rem',
}

// markup
const NotFoundPage = () => {
  return (
    <Layout isSubMenuOpen={true}>
      <Grid.FullWidthSection as="section" css={{
        //height: `752px`,
        paddingTop: '6rem',
        backgroundColor: `#6dbccc`,
        boxShadow: 'inset 0px 1px 6px 0px rgba(50, 50, 50, 0.50)',
      }}>
        <Grid.Block start="1" span="14" css={{}}>
          <div css={{
            display: `flex`,
            flexDirection: 'column',
            alignItems: `center`,
            textAlign: `center`,
            
            '@media (min-width: 1200px)': {
              flexDirection: 'row',
              textAlign: 'left',
              justifyContent: `center`,
              textAlign: `left`,
              alignItems: `start`,
            }
          }}>
            <div css={{padding:`0 1.5rem`,}}>
              <h1 css={{
                lineHeight: '1',
                fontFamily: `proxima-nova, "Lucida Sans Unicode", "Lucida Grande", sans-serif`,
                fontWeight: `600`,
                color: `#fff`,
                textTransform: `uppercase`,
                textShadow: `0 0 10px rgba(0,0,0,0.4)`,
                letterSpacing: `0.05em`,
              }}>
                <SizedText as="span" smallSize="10.699" largeSize="15.014">
                  404
                </SizedText>
              </h1>
              <h2 css={{color: `#16405b`}}>
                <SizedText as="span" smallSize="2.074" largeSize="2.281">
                  Sorry, this page could not be found.
                </SizedText>
              </h2>
              <p css={{color: `#22313f`}}>
                <SizedText as="span" smallSize="1.2" largeSize="1.266">
                  The link you followed is probably broken or the page has been removed.
                </SizedText>
              </p>
              <div css={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '2rem',
                justifyContent: 'center',
                '@media (min-width: 1200px)': {
                  justifyContent: 'left',
                  '& a, button': {
                    margin: '0 2rem 0 0',
                  }
                }
              }}>
                <button css={{...buttonStyles}} onClick={() => window.history.back()}>
                  <span>B</span>
                </button>
                <Link css={{...buttonStyles}} to="/">
                  <span>H</span>
                </Link>
                <Link css={{...buttonStyles}} to="/search">
                  <span>S</span>
                </Link>
              </div>
            </div>
            <div css={{
              margin: `4rem 0 0 0`,
              '@media (min-width: 1200px)': { marginLeft: `0 0 0 6rem` }
              
            }}>
              <StaticImage src="../images/404/pageNotFound-mobile.png" alt="" height="275" width="864" css={{
                '@media (min-width: 1200px)': { display: `none !important`}
              }}/>
              <StaticImage src="../images/404/pageNotFound.png" alt="" width="521" height="620" css={{
                display: `none !important`,
                '@media (min-width: 1200px)': { display: `block !important`}
              }}/>
            </div>
          </div>
        </Grid.Block>
      </Grid.FullWidthSection> 
    </Layout>
  )
}

export default NotFoundPage
